import { SectionContainer } from "../Section";
import { Icon } from "@iconify/react";
import { useState } from "react";
import { v4 as uuid } from "uuid";
import clsx from "clsx";

const accordionData = [
  {
    id: uuid(),
    title: "Czym dokładnie zajmuje sie Państwa firma?",
    isOpen: true,
    content:
      "Nasza firma specjalizuje się w konstrukcjach drewnianych. Oferujemy zadaszenia tarasów, ruchome żaluzje drewniane, tarasy, wiaty, carporty, domki narzędziowe i wiele innych produktów drewnianych dostosowanych do potrzeb naszych klientów.",
  },
  {
    id: uuid(),
    title: "Czy oferujecie gwarancję na swoje usługi?",
    isOpen: false,
    content:
      "Tak, oferujemy 24-miesięczną gwarancję na wszystkie nasze produkty i usługi.",
  },
  {
    id: uuid(),
    title: "Jakie materiały używacie do konstrukcji drewnianych?",
    isOpen: false,
    content: "Używamy wysokiej jakości drewna C24 w kategorii KVH oraz BSH.",
  },
  {
    id: uuid(),
    title:
      "Czy oferujecie zadaszenia tarasów dostosowane do indywidualnych wymiarów i potrzeb klienta?",
    isOpen: false,
    content:
      "Tak, wszystkie nasze zadaszenia tarasów są dostosowywane do indywidualnych wymiarów i potrzeb naszych klientów.",
  },
  {
    id: uuid(),
    title: "Czy Państwa konstrukcje są odporne na warunki atmosferyczne?",
    isOpen: false,
    content:
      "Tak, realizowane przez nas konstrukcje są odporne na warunki atmosferyczne. Przed przystąpieniem do montażu u klienta impregnujemy drewno wysokiej jakości produktami firmy Koopmans lub środkiem impregnującym wybranym indywidualnie przez klienta.",
  },
  {
    id: uuid(),
    title: "Jak długo trwa realizacja od momentu podpisania umowy?",
    isOpen: false,
    content:
      "Termin rozpoczęcia prac zależny jest od obłożenia, lecz podajemy przybliżony termin przed podpisaniem umowy jak i informujemy o przybliżonym czasie montażu.",
  },
  {
    id: uuid(),
    title: "Jakie są zalecenia dotyczące pielęgnacji i konserwacji drewnianych konstrukcji?",
    isOpen: false,
    content:
      "Zalecamy regularne czyszczenie powierzchni drewnianych łagodnym środkiem czyszczącym oraz stosowanie odpowiednich środków ochronnych co 2 lata (w przypadku tarasów raz w roku), aby przedłużyć żywotność konstrukcji i zachować ich naturalny wygląd.",
  },
  {
    id: uuid(),
    title: "Czy oferujecie usługi serwisowe po zakupie?",
    isOpen: false,
    content:
      "Tak, oferujemy usługi serwisowe i pielęgnacyjne dla wszystkich naszych produktów.",
  },
  {
    id: uuid(),
    title: "Czy oferujecie usługi doradztwa w zakresie wyboru odpowiednich rozwiązań dla mojego domu/ogrodu?",
    isOpen: false,
    content:
      "Tak, w momencie pomiarów doradzamy oraz dzielimy się doświadczeniem, aby pomóc w wyborze odpowiednich rozwiązań.",
  },
];

const accordionItemType = {
  top: "rounded-t-lg",
  default: "border rounded-none border-t-0",
  bottom: "border border-t-0 rounded-b-lg",
};

export const Accordion = () => {
  const [activeAccordion, setActiveAccordion] = useState(null);

  const accordionClickHandle = (id) => {
    setActiveAccordion(id === activeAccordion ? null : id);
  };

  return (
    <SectionContainer className="accordion--container my-16 drop-shadow-xl max-w-3xl mx-auto offset-y-0 offset-x-8">
      {accordionData.map((accordionItem, index) => (
        <div
          key={accordionItem.id}
          id={accordionItem.id}
          className={clsx(
            "accordion-item--container border border-neutral-200 bg-white overflow-hidden",
            {
              [accordionItemType.top]: index === 0,
              [accordionItemType.default]:
                index > 0 && index < accordionData.length - 1,
              [accordionItemType.bottom]: index === accordionData.length - 1,
            }
          )}
        >
          <h2 className="accordion-item--heading mb-0">
            <button
              className="group relative flex w-full font-semibold items-center rounded-t-lg border-0 bg-white py-4 px-5 text-left text-base text-neutral-800 transition"
              type="button"
              aria-expanded={accordionItem.isOpen}
              onClick={() => accordionClickHandle(accordionItem.id)}
            >
              {accordionItem.title}
              <Icon
                icon="material-symbols:keyboard-arrow-up-rounded"
                className="ml-auto h-8 w-8 shrink-0 rotate-[-180deg] transition-transform duration-200 ease-in-out motion-reduce:transition-none"
              />
            </button>
          </h2>
          <div
            className={clsx("accordion-item--content py-4 px-5 text-base", {
              hidden: activeAccordion !== accordionItem.id, // Use hidden class to animate height to 0
              "!visibility block": activeAccordion === accordionItem.id, // Use block class to show content again
            })}
          >
            <p>{accordionItem.content}</p>
          </div>
        </div>
      ))}
    </SectionContainer>
  );
};
