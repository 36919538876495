import { SectionContainer } from "../Section";
import Image from "next/image";
import { v4 as uuid } from "uuid";

const ContentImageData = [
  {
    id: uuid(),
    title: "Zadaszenia tarasów ☀️☔",
    content:
      "Zadaszenia tarasów to gwarancja, że będziesz mógł cieszyć się swoim tarasem przez cały rok. Chronią Twój taras przed deszczem i słońcem, zapewniając optymalne warunki do relaksu i spotkań na świeżym powietrzu. To inwestycja w komfort Twojego domu, która zyskuje na znaczeniu w każdych warunkach pogodowych.",
    align: "right",
    image: "/oferta/zadaszenie-tarasu.webp",
  },
  {
    id: uuid(),
    title: "Pergole 🌿",
    content:
      "Pergole to nie tylko praktyczne rozwiązanie, ale także idealna forma relaksu w Twoim ogrodzie. Dzięki nim stworzysz przytulne miejsce, w którym odpoczniesz w cieniu i oddasz się urokowi świeżego powietrza. Nasze pergole łączą funkcjonalność z estetyką, tworząc wyjątkową przestrzeń do spędzania czasu na świeżym powietrzu.",
    align: "left",
    image: "/oferta/pergole.webp",
  },
  {
    id: uuid(),
    title: "Carporty 🚗",
    content:
      "Nasze carporty to doskonałe rozwiązanie dla właścicieli pojazdów, którzy chcą chronić swoje auta przed warunkami atmosferycznymi. Oferujemy nie tylko ochronę przed deszczem i słońcem, ale także elegancki design, który wpisuje się w estetykę Twojego domu. Dzięki carportom zyskasz spokojną głowę i wygodę parkowania.",
    align: "right",
    image: "/oferta/carporty.webp",
  },
  {
    id: uuid(),
    title: "Wiata 🏡",
    content:
      "Nasze wiaty to więcej niż tylko dodatkowa przestrzeń. To miejsce, w którym możesz przechowywać narzędzia, sprzęt ogrodniczy czy inne przedmioty, z dala od warunków atmosferycznych. Nasze wiaty to funkcjonalność połączona z estetyką, dodając nowy wymiar Twojemu ogrodowi i domowi.",
    align: "left",
    image: "/oferta/wiata.webp",
  },
  {
    id: uuid(),
    title: "Drewniane tarasy 🌳",
    content:
      "Nasze drewniane tarasy to nie tylko piękna i naturalna przestrzeń, ale także wyjątkowe miejsce do spędzania czasu na świeżym powietrzu. Wykorzystujemy najlepsze materiały, aby stworzyć trwałe i estetyczne tarasy, które będą dumą Twojego domu. To idealne miejsce na spotkania rodzinne i relaks pod gołym niebem.",
    align: "right",
    image: "/oferta/tarasy.webp",
  },
  {
    id: uuid(),
    title: "Żaluzje drewniane 🪟",
    content:
      "Nasze żaluzje drewniane to eleganckie i praktyczne rozwiązanie, które pozwala kontrolować ilość światła wpadającego do pomieszczenia. To nie tylko element dekoracyjny, ale także narzędzie do regulowania klimatu w Twoim wnętrzu. Oferujemy najwyższą jakość i trwałość, by zapewnić Ci komfort i styl.",
    align: "left",
    image: "/oferta/zaluzje-drewniane.webp",
  },
  {
    id: uuid(),
    title: "Domki narzędziowe 🧰",
    content:
      "Nasze domki narzędziowe to funkcjonalne i estetyczne budowle, idealne na przechowywanie narzędzi ogrodniczych. Projektujemy je z myślą o Twoich potrzebach, zapewniając optymalne warunki do przechowywania i utrzymania porządku w ogrodzie. Nasze domki narzędziowe to miejsce, w którym wszystko jest zawsze pod ręką.",
    align: "right",
    image: "/oferta/domek-narzedziowy.webp",
  },
  {
    id: uuid(),
    title: "Żagle Rzymskie 🏠",
    content:
      "Nasze żagle rzymskie to dekoracyjne osłony okienne, które nadadzą wnętrzom wyjątkowego charakteru. Projektujemy je z dbałością o detale, tworząc eleganckie rozwiązania, które dostosowują ilość światła i zapewniają prywatność. To sposób na podkreślenie stylu i wyjątkowości Twojego wnętrza.",
    align: "left",
    image: "/oferta/zagle-rzymskie.webp",
  },
  {
    id: uuid(),
    title: "Inne konstrukcje drewniane 🏗️",
    content:
      "Tworzymy spersonalizowane rozwiązania, dopasowane do Twoich potrzeb. Nasze konstrukcje drewniane to kreatywna odpowiedź na Twoje marzenia o wyjątkowej przestrzeni. Niezależnie od projektu, nasza firma gwarantuje jakość, trwałość i estetykę, które spełnią Twoje oczekiwania.",
    align: "right",
    image: "/oferta/inne-konstrukcje.webp",
  },
];

export const ContentImage = () => {
  return (
    <SectionContainer className="process-items mt-16 space-y-32">
      {ContentImageData.map((item) => (
        <div
          id={item.id}
          key={item.id}
          className="process-item--container grid md:grid-cols-2 gap-y-8"
        >
          <div
            className={`process-item--image rounded-3xl ${
              item.align === "left" ? "md:order-1" : ""
            }`}
          >
            <Image
              src={item.image}
              width={400}
              height={225}
              alt={`zdjęcie do sekcji ${item.title}`}
              className="drop-shadow-xl w-full offset-y-0 offset-x-8 blur-16 rounded-lg"
              loading="lazy"
              sizes="(max-width: 640px) 50vw, 20vw)"
            />
          </div>
          <div
            className={`process-item--content ${
              item.align === "left"
                ? "md:pr-16 lg:pr-24 xl:pr-32 ml-auto"
                : "md:pl-16 lg:pl-24 xl:pl-32  mr-auto"
            } my-auto content text-black/60`}
          >
            <h2 className="mb-6 h4 md:h3 font-semibold text-black">
              {item.title}
            </h2>
            <p>{item.content}</p>
          </div>
        </div>
      ))}
    </SectionContainer>
  );
};
