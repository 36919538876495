import { useDialog } from "@hooks/useDialog";
import { Button } from "../Button/Button";
import { ButtonGroup } from "../Button/ButtonGroup";
import { Content } from "../Content";
import Dot from "../Dot";
import MotionBTTContainer from "../Motion/MotionBTTContainer";
import { SectionContainer } from "../Section";
import { PageTitle } from "../Title/PageTitle";
import { Icon } from "@iconify/react";
import ShapeDivider from "components/ShapeDivider";
import LogoRounded from "components/LogoRounded";

export const HomeBanner = () => {
  const { openDialog } = useDialog();

  return (
    <>
      <SectionContainer className="page-banner--container pt-28 py-16 bg-[#493939] -z-20 relative pb-[105px]">
      <ShapeDivider />
      <LogoRounded />
        <div className="absolute top-0 left-0 w-full h-full bg-[url('/overlay2.webp')] opacity-50 -z-10" />
        <SectionContainer className="page-banner--inner-container wrap wrap-px z-10">
          {/* Appear First */}
          <MotionBTTContainer transition={{ delay: 0.2, duration: 0.5 }}>
            <PageTitle
              className="py-4 md:py-8 text-center mx-auto text-white md:leading-[4rem] text-[28px] md:text-3xl"
              type="heavy"
            >
              Tworzymy konstrukcje z drewna, które dodadzą uroku Twojemu
              otoczeniu.
            </PageTitle>
          </MotionBTTContainer>
          {/* Appear Second */}

          {/* Appear Third */}
          <MotionBTTContainer transition={{ delay: 0.6, duration: 0.5 }}>
            <div className="flex justify-center">
              <Content className="text-center items-center" alignment="center">
                <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 justify-center items-center flex-wrap text-lg md:text-xl">
                  <p className="my-0 text-white-600">wiaty zadaszenia</p>
                  <Dot />
                  <p className="my-0 text-white-600">konstrukcje drewniane</p>
                  <Dot />
                  <p className="my-0 text-white-600">
                    zadaszenia tarasu zachodniopomorskie
                  </p>
                </div>
              </Content>
            </div>

            <div className="mt-12 mb-16 md:mb-40 text-center">
              <ButtonGroup alignment="center">
                <Button href="#portfolio">Realizacje</Button>
                <div
                  onClick={() => openDialog()}
                  className="btn btn--secondary"
                >
                  Poznaj wycenę
                  <Icon icon="material-symbols:arrow-forward-rounded" />
                </div>
              </ButtonGroup>
            </div>
          </MotionBTTContainer>
        </SectionContainer>
      </SectionContainer>
    </>
  );
};
