import Image from "next/image";
import MotionBTTContainer from "../Motion/MotionBTTContainer";
import { useEffect, useState } from "react";
import { set } from "date-fns";

const Timeline = () => {
  const [windowWidth, setWindowWidth] = useState(0);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWindowWidth(window.innerWidth);
    });
    setWindowWidth(window.innerWidth);
  }, []);
  return (
    <div className="container mx-auto w-full h-full">
      <div className="relative wrap overflow-hidden py-8 md:p-10 h-full">
        {windowWidth > 768 && (
          <div
            className="border-2-2 absolute border-opacity-40 border-gray-700 h-full border-2 border-dashed"
            style={{ left: "50%" }}
          ></div>
        )}

        <MotionBTTContainer transition={{ delay: 0.5, duration: 0.5 }}>
          <div className="mb-8 flex justify-between items-center w-full right-timeline relative">
            {windowWidth > 768 && (
              <>
                <div className="order-1 w-full md:w-5/12"></div>
                <div className="z-20 flex items-center order-1 bg-[#6d6d6d] shadow-xl w-6 h-6 rounded-full"></div>
              </>
            )}

            <div className="border relative order-1 bg-gray-50 rounded-lg shadow-xl w-full md:w-5/12 px-6 py-4 before:content-[''] before:border-2-2 before:absolute before:border-opacity-40 before:border-gray-700 before:w-1/2 before:top-1/2 before:-left-20 before:border-2 before:border-dashed before:-z-20">
              <div className="mt-2 mb-6">
                <Image
                  src="/icons/pencil.png"
                  width={96}
                  height={96}
                  alt="ikona pomiarów"
                />
              </div>

              <h2 className="mb-3 font-bold text-gray-800 text-xl">POMIARY</h2>
              <p className="text-sm leading-snug tracking-wide text-gray-900 text-opacity-100">
                Dokładne pomiary to fundament udanego projektu. Odwiedzimy Twój
                teren, aby dokładnie zbadać przestrzeń i zrozumieć Twoje
                potrzeby. Dzięki temu możemy zagwarantować, że nasza konstrukcja
                drewniana będzie idealnie dopasowana do Twoich wymagań.
              </p>
            </div>
          </div>
        </MotionBTTContainer>

        <MotionBTTContainer transition={{ delay: 0.5, duration: 0.5 }}>
          <div className="mb-8 flex justify-between flex-row-reverse items-center w-full left-timeline">
            {windowWidth > 768 && (
              <>
                <div className="order-1 w-5/12"></div>
                <div className="z-20 flex items-center order-1 bg-[#6d6d6d] shadow-xl w-6 h-6 rounded-full"></div>
              </>
            )}

            <div className="border relative order-1 bg-gray-50 rounded-lg shadow-xl w-full md:w-5/12 px-6 py-4 before:content-[''] before:border-2-2 before:absolute before:border-opacity-40 before:border-gray-700 before:w-1/2 before:top-1/2 before:-right-20 before:border-2 before:border-dashed before:-z-20">
              <div className="flex md:justify-end mt-2 mb-6">
                <Image
                  src="/icons/document.png"
                  width={96}
                  height={96}
                  alt="ikona pomiarów"
                />
              </div>
              <h2 className="mb-3 font-bold text-gray-800 text-xl md:text-right">
                WYCENA
              </h2>
              <p className="text-sm leading-snug tracking-wide text-gray-900  text-opacity-100 md:text-right">
                Po dokonaniu dokładnych pomiarów przygotowujemy dla Ciebie
                szczegółową wycenę na podstawie Twoich oczekiwań względem
                konstrukcji.
              </p>
            </div>
          </div>
        </MotionBTTContainer>
        <MotionBTTContainer transition={{ delay: 0.5, duration: 0.5 }}>
          <div className="mb-8 flex justify-between items-center w-full right-timeline relative">
            {windowWidth > 768 && (
              <>
                <div className="order-1 w-full md:w-5/12"></div>
                <div className="z-20 flex items-center order-1 bg-[#6d6d6d] shadow-xl w-6 h-6 rounded-full"></div>
              </>
            )}
            <div className="border relative order-1 bg-gray-50 rounded-lg shadow-xl w-full md:w-5/12 px-6 py-4 before:content-[''] before:border-2-2 before:absolute before:border-opacity-40 before:border-gray-700 before:w-1/2 before:top-1/2 before:-left-20 before:border-2 before:border-dashed before:-z-20">
              <div className="mt-2 mb-6">
                <Image
                  src="/icons/handshake.png"
                  width={96}
                  height={96}
                  alt="ikona pomiarów"
                />
              </div>

              <h2 className="mb-3 font-bold text-gray-800 text-xl">UMOWA</h2>
              <p className="text-sm leading-snug tracking-wide text-gray-900 text-opacity-100">
                Kiedy jesteś gotowy do kontynuacji, podpisujemy umowę. Dokładnie
                omawiamy wszystkie jej elementy, aby upewnić się, że obie strony
                mają jasność co do zakresu prac, kosztów oraz terminów.
                Bezpieczeństwo i przejrzystość są dla nas priorytetem.
              </p>
            </div>
          </div>
        </MotionBTTContainer>
        <MotionBTTContainer transition={{ delay: 0.5, duration: 0.5 }}>
          <div className="mb-8 flex justify-between flex-row-reverse items-center w-full left-timeline">
            {windowWidth > 768 && (
              <>
                <div className="order-1 w-5/12"></div>
                <div className="z-20 flex items-center order-1 bg-[#6d6d6d] shadow-xl w-6 h-6 rounded-full"></div>
              </>
            )}

            <div className="border relative order-1 bg-gray-50 rounded-lg shadow-xl w-full md:w-5/12 px-6 py-4 before:content-[''] before:border-2-2 before:absolute before:border-opacity-40 before:border-gray-700 before:w-1/2 before:top-1/2 before:-right-20 before:border-2 before:border-dashed before:-z-20">
              <div className="flex md:justify-end mt-2 mb-6">
                <Image
                  src="/icons/saw.png"
                  width={96}
                  height={96}
                  alt="ikona pomiarów"
                />
              </div>
              <h2 className="mb-3 font-bold text-gray-800  text-xl md:text-right">
                REALIZACJA
              </h2>
              <p className="text-sm leading-snug tracking-wide text-gray-900  text-opacity-100 md:text-right">
                Ten etap to serce całego procesu. Rozpoczynamy budowę Twojej
                konstrukcji drewnianej z pełnym zaangażowaniem i
                profesjonalizmem. Każdy element jest dokładnie analizowany i
                montowany z naciskiem na trwałość i estetykę. Współpracujemy z
                Tobą na każdym kroku, informując o postępach prac i upewniając
                się, że wszystko przebiega zgodnie z Twoimi oczekiwaniami.
              </p>
            </div>
          </div>
        </MotionBTTContainer>
      </div>
    </div>
  );
};

export default Timeline;
