import { Layout } from "../components/Layout";
import SEO from "../components/SEO";
import { HomeBanner } from "../components/Banner";
import { SectionContainer } from "../components/Section";
import { Content } from "../components/Content";
import MotionBTTContainer from "../components/Motion/MotionBTTContainer";
import { PageTitle } from "../components/Title/PageTitle";
import { Accordion } from "../components/Accordion/Accordion";
import { ContentImage } from "../components/ContentImage/ContentImage";
import Timeline from "../components/Timeline";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";

const DynamicGallerySection = dynamic(() => import("../components/GallerySection/GallerySection"), {
  ssr: false,
});

export default function Index() {
  const router = useRouter();
  return (
    <Layout>
      <SEO
        title="Handyman - Konstrukcje drewniane, zadaszenia wiaty tarasy | Stargard | Szczecin"
        description="Nasza firma specjalizuje się w budowie konstrukcji drewnianych, zadaszeń, wiat, oraz tarasów w Stargardzie i Szczecinie. Zapewniamy profesjonalne usługi konstrukcyjne dla Twojego ogrodu. Skontaktuj się z nami już dziś!"
        link={router.pathname}
        ratingValue={5.0}
        ratingCount={14}
      />
      <div
        className={`main-wrapper relative z-10 pb-20 pt-0 transition-colors duration-500`}
      >
        {/* { Page Banner } */}
        <HomeBanner />
        {/* Components Container */}
        <SectionContainer className="components--container relative wrap wrap-px grid gap-8 sm:gap-24">
          {/* Portfolio */}
          <MotionBTTContainer transition={{ delay: 0.2, duration: 0.5 }}>
            <SectionContainer id="portfolio" className="benefits pt-28">
              {/* <BadgeGroup alignment="left">
                <BadgeMessage>Realizacje</BadgeMessage>
                <BadgeIcon icon="twemoji:camera" />
              </BadgeGroup> */}
              <PageTitle className="" type="default">
                Realizacje drewnianych konstrukcji. Wiaty, zadaszenia tarasu i
                więcej!
              </PageTitle>
              <p>Wybierz jedną z konstrukcji aby zobaczyć etapy prac</p>
              {/* <Columns /> */}
              <DynamicGallerySection />
            </SectionContainer>
          </MotionBTTContainer>
          {/* Features */}
          <MotionBTTContainer transition={{ delay: 0.2, duration: 0.5 }}>
            <SectionContainer
              id="oferta"
              className="features flex flex-col items-center pt-32"
            >
              {/* <BadgeGroup alignment="center">
                <BadgeMessage>
                  Nasza Oferta - Konstrukcje Drewniane
                </BadgeMessage>
                <BadgeIcon icon="twemoji:check-mark"></BadgeIcon>
              </BadgeGroup> */}
              <PageTitle className="text-left md:text-center mx-auto" type="default">
                Witaj na stronie 🪵HANDYMAN - Twojego partnera w dziedzinie
                konstrukcji drewnianych!
              </PageTitle>
              <Content className="text-left md:text-center" alignment="center">
                <p>
                  Jesteśmy dumni, że możemy dostarczać najwyższej jakości
                  konstrukcje drewniane na terenie zachodniopomorskiego,
                  obsługując miasta takie jak Szczecin, Stargard i Grzędzice.
                  Nasza firma specjalizuje się w projektowaniu, budowie i
                  montażu różnorodnych konstrukcji drewnianych, które dodadzą
                  uroku Twojemu otoczeniu oraz zabezpieczą Cię przed
                  niekorzystnymi warunkami atmosferycznymi.
                </p>
              </Content>
              <ContentImage />
            </SectionContainer>
          </MotionBTTContainer>
          {/* Sposób pracy */}
          <MotionBTTContainer transition={{ delay: 0.2, duration: 0.5 }}>
            <SectionContainer className="feature-tabs flex flex-col items-center pt-28">
              {/* <BadgeGroup alignment="center">
                <BadgeMessage>Usystematyzowany sposób pracy</BadgeMessage>
                <BadgeIcon icon="twemoji:flexed-biceps" />
              </BadgeGroup> */}
              <PageTitle className="text-left md:text-center mx-auto" type="default">
                4 kroki do realizacji Twojego projektu
              </PageTitle>
              <Content className="text-left md:text-center" alignment="center">
                <p className="text-lg">
                  Zrealizuj swoje marzenia o idealnej przestrzeni za pomocą
                  naszych profesjonalnych usług stolarskich. Poznaj nasze 4
                  kluczowe kroki, które poprowadzą Cię od pomysłu do realizacji
                  - spełnij swoje oczekiwania z nami!
                </p>
              </Content>
              <Timeline />
            </SectionContainer>
          </MotionBTTContainer>
          {/* FAQ */}
          <MotionBTTContainer transition={{ delay: 0.2, duration: 0.5 }}>
            <SectionContainer id="faq" className="faq pt-28">
              {/* <BadgeGroup alignment="center">
                <BadgeMessage>FAQ</BadgeMessage>
                <BadgeIcon icon="twemoji:question-mark" />
              </BadgeGroup> */}
              <PageTitle className="text-center mx-auto" type="default">
                Masz jakieś pytania dotyczące naszej pracy?<br></br>
                <br></br>Bez obaw! Mamy odpowiedzi, których potrzebujesz:
              </PageTitle>
              <Accordion />
            </SectionContainer>
          </MotionBTTContainer>
        </SectionContainer>
      </div>
    </Layout>
  );
};
