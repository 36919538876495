import Image from "next/image";

const imageStyle = {
  background: "transparent",
};

const LogoRounded = () => (
  <div className="flex justify-center items-center z-50 w-32 h-32 absolute -bottom-6 -translate-x-1/2 left-1/2 rounded-full ">
    <Image
      src="/wood-carousel1.webp"
      alt="animowany plaster drzewa"
      className="h-28 w-auto animate-spin-slow"
      height="128"
      width="128"
      style={imageStyle}
      priority
    />
  </div>
);

export default LogoRounded;
